import { graphql, useStaticQuery } from "gatsby";

const useCategories = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpCategory(filter: {name: {nin: ["Uncategorized", "Uncategorised"]}}) {
        nodes{
          id
          
          name
          uri
          description
          category{
            background
          }
          posts{
            ... on WpCategoryToPostConnection{
              nodes{
                id
                title
                uri
                date
                article {
                  image {
                    ... Image
                  }
                  summary
                  readTime
                }
              }
            }
          }
        }
      }
    }
  `);

  return data?.allWpCategory;
};

export default useCategories;
