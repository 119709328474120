import { useField } from "formik";
import { Icon, IconType } from "~/components/elements/Icon";
import React from "react";

export default function NewsletterInputField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <div
      className={` ${props.className || ""}`}>
      <label className={`screenreader-text`}>
        {label}
      </label>
      <input 
        type="text" 
        className="border-b-2 border-b-navy-dark bg-transparent py-3 w-full text-[22px] outline-none placeholder-navy-dark" 
        placeholder="Enter your email address"
        {...field}
        {...other} />
      <button type="submit" className="absolute top-1/2 right-0 transform -translate-y-1/2">
        <div className="w-5 fw-icon">
          <Icon type={IconType.ArrowRight} />
        </div>
      </button>
    </div>
  );
}



