import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import Image from '~/components/elements/Image';
import { WpPage_FlexibleContent_FlexibleContent_Newsletter } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import { Button, ButtonType, ButtonSize } from "~/components/elements/buttons/Button"
import { ButtonColor } from '~/components/elements/buttons/Button';
import { Icon, IconType } from "~/components/elements/Icon";
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible'
import NewsletterForm from '~/components/elements/NewsletterForm';

export default function Newsletter({
  section,
  heading,
  content,
}: WpPage_FlexibleContent_FlexibleContent_Newsletter) {
  return (
    <Section {...section} layoutName="Newsletter">
      <FadeInWhenVisible className="container">
        <div className=" bg-teal py-20 md:py-40 px-12">
          <div className={`max-w-[620px] mx-auto text-center space-y-12 `}>
            <div className="space-y-8">
              <h3 className={`text-h1 text-h1--large tracking-[-.018em] font-normal`} dangerouslySetInnerHTML={{__html: heading}} />
              <div className={`prose p-xxlarge `} dangerouslySetInnerHTML={{__html: content}} />
            </div>
            <NewsletterForm />
          </div>
        </div>
      </FadeInWhenVisible>
    </Section>
  )
}
