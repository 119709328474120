import React from 'react';
import Image from '~/components/elements/Image';
import { WpPage_FlexibleContent_FlexibleContent_FeaturedResources } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import FadeInWhenVisible from '~/components/flexible/FadeInWhenVisible';
import { Button, ButtonType, ButtonSize, ButtonColor } from '~/components/elements/buttons/Button';
import { Link } from 'gatsby';
import moment from 'moment';
import { ArticleInfo } from './Resources';
import { StaticImage } from 'gatsby-plugin-image';
import { getCookieLink } from '../../hooks/setCookies';

function getUTMLink(url) {
  return getCookieLink(url || '');
}

export default function FeaturedResources({ posts }: WpPage_FlexibleContent_FlexibleContent_FeaturedResources) {
  if (!posts) return null;

  return (
    <Section layoutName="FeaturedResources">
      <div className="container">
        <FadeInWhenVisible className="space-y-8 lg:space-y-0 lg:grid grid-cols-3 gap-x-8">
          <div className="col-span-2 grid gap-y-8">
            <FeaturedResources1 post={posts[0]} />
            <FeaturedResources2 post={posts[1]} />
          </div>
          <div className="col-span-1 grid gap-y-8 grid-rows-5  ">
            <FeaturedResources3 post={posts[2]} />
            <FeaturedResources4 post={posts[3]} />
          </div>
        </FadeInWhenVisible>
      </div>
    </Section>
  );
}

export const FeaturedResources1 = ({ post }) => {
  const linkUrl = getUTMLink(post?.uri);
  return (
    <Link to={`${post?.uri}${linkUrl}`} className="relative w-full group">
      <div className="aspect-w-2 aspect-h-1 w-full overflow-hidden">
        <Image
          image={post?.featuredImage?.node}
          objectFit="cover"
          className="!absolute transform group-hover:scale-110 duration-1000 ease-in-out"
        />
      </div>
      <div className="absolute bottom-0 left-0 bg-white p-8 w-11/12 lg:max-w-[433px] space-y-5">
        <ArticleInfo article={post?.article} uri={post?.uri} date={post?.date} />
        <h5 className="text-h5 max-w-[332px]">{post.title}</h5>
      </div>
    </Link>
  );
};

export const FeaturedResources2 = ({ post }) => {
  const linkUrl = getUTMLink(post?.uri);
  return (
    <Link to={`${post?.uri}${linkUrl}`} className="relative w-full group">
      <div className="aspect-w-5 aspect-h-3 w-full overflow-hidden">
        <Image
          image={post?.featuredImage?.node}
          objectFit="cover"
          className="!absolute transform group-hover:scale-110 duration-1000 ease-in-out"
        />
      </div>
      <div className="absolute bottom-0 left-0 bg-white p-8 w-11/12 lg:max-w-[663px] space-y-5">
        <ArticleInfo article={post?.article} uri={post?.uri} date={post?.date} />
        <h5 className="text-h5">{post.title}</h5>
      </div>
    </Link>
  );
};

export const FeaturedResources3 = ({ post }) => {
  const linkUrl = getUTMLink(post?.uri);
  return (
    <Link
      to={`${post?.uri}${linkUrl}`}
      className="row-span-3 relative w-full group bg-beige flex flex-col justify-end space-y-16">
      <div className="h-full w-full overflow-hidden ">
        <Image
          image={post?.featuredImage?.node}
          objectFit="cover"
          className="h-full transform group-hover:scale-110 duration-1000 ease-in-out"
        />
      </div>
      <div className="absolute bottom-0 left-0 w-11/12 bg-white p-8  space-y-5">
        <ArticleInfo article={post?.article} uri={post?.uri} date={post?.date} />
        <h5 className="text-h5">{post.title}</h5>
      </div>
    </Link>
  );
};

export const FeaturedResources4 = ({ post }) => {
  const linkUrl = getUTMLink(post?.uri);
  return (
    <Link to={`${post?.uri}${linkUrl}`} className="row-span-2 relative w-full group">
      <div className="h-full w-full overflow-hidden ">
        <Image
          image={post?.featuredImage?.node}
          objectFit="cover"
          className="h-full transform group-hover:scale-110 duration-1000 ease-in-out"
        />
      </div>
      <div className="absolute bottom-0 left-0 w-11/12 bg-white p-8  space-y-5">
        <ArticleInfo article={post?.article} uri={post?.uri} date={post?.date} />
        <h5 className="text-h5">{post.title}</h5>
      </div>
    </Link>
  );
};
