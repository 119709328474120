import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import NewsletterInputField from "~/components/elements/forms/NewsletterInputField";
import useFormSubmit from "~/hooks/useFormSubmit"

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
});

export default function NewsletterForm() {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { post, loading, response } = useFormSubmit({
    portalId: '5160067',
    formId: 'bfad7250-ff21-4309-9822-288dafa28faf',
    setIsSubmitted,
  });

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await post(values);
        if (response.status === 200) {
          setIsSubmitted(true);
          setSuccessMessage(response?.data?.inlineMessage);
        } else if (response?.data?.status === 'error') {
          let str = response?.data?.message + '<br/>';
          response?.data.errors.map((err) => {
            str += '<br/>' + err.message;
          });
          setErrorMessage(str);
        }
      }}
    >
      {(props) => (
        <Form className="relative max-w-[393px] mx-auto">
          {isSubmitted ? (
            <div className="p-xxlarge font-medium" dangerouslySetInnerHTML={{ __html: successMessage }} />
          ) : (
            <>
              <NewsletterInputField name="email" label="Email*" />

              {errorMessage?.length > 0 && (
                <div
                  className="mt-4 text-red"
                  dangerouslySetInnerHTML={{ __html: errorMessage }}
                />
              )}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}